import React, { useState, useEffect } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ic_plus_big from "../assets/ic_plus_big.svg"
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { auth, storage } from '../firebaseUtils'
import ReactLoading from 'react-loading';
import { PartCondition, ShippingMode, formatNumber } from '../utils';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify'
import { getFirebaseTokenThenSignin, AccountObj, makeAuthenticatedRequest, makeid, truncateNumber, axiosCustom } from '../utils'
import { ENDPOINT_INVENTORY, ENDPOINT_INVENTORY_ADD } from '../routes';
import { PartType } from '../constants';

import Compressor from 'compressorjs';


const MERCHANTS_URL = `/oms/all-merchants/`

export const compresedImage = (file) =>
   new Promise((resolve) => {
      new Compressor(file, {
         minWidth: 640,
         maxHeight: 640,
         mimeType: 'image/webp',
         success(result) {
            resolve(result)
         },
         error(err) {
            console.log(err.message);
         },
      });
   });

const handleFileUploadOnFirebaseStorage = async (bucketName, uploadedImage) => {
   // 1. If no file, return
   if (uploadedImage === "" || uploadedImage === null) return "";
   // 2. Put the file into bucketName
   const fileID = makeid(20);
   const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);
   // const file = await fetch(uploadedImage).then(r => r.blob()).then(blobFile => new File([blobFile], fileID, { type: blobFile.type }))
   // const options = {
   //    maxSizeMB: 0.1,
   //    maxWidthOrHeight: 640,
   //    useWebWorker: true
   // }
   // const compressedFile = await imageCompression(file, options);
   await uploadBytesResumable(storageRef, uploadedImage);
   let downloadURL = null;
   await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
   return downloadURL
};
const handleFilesUploadOnFirebaseStorage = async (uploadedImages, merchantID) => {

   const bucketName = `/merchant/${merchantID}/inventory`
   // 1. If no file, return
   if (uploadedImages.length === 0) return [];
   // 2. Create an array to store all download URLs
   let fileUrls = [];
   // 3. Loop over all the files
   for (var i = 0; i < uploadedImages.length; i++) {
      // 3A. Get a file to upload
      const uploadedImage = uploadedImages[i];
      // 3B. handleFileUploadOnFirebaseStorage function is in above section
      const downloadFileResponse = await handleFileUploadOnFirebaseStorage(bucketName, uploadedImage);
      // 3C. Push the download url to URLs array
      fileUrls.push(downloadFileResponse);
   }
   return fileUrls;
};
function InventoryAdd() {

   const [moq, setMoq] = useState()
   const [receivePrice, setReceivePrice] = useState();

   const [commission, setCommission] = useState(10);
   const [wholesaleCommission, setWholesaleCommission] = useState(8);

   const [returnPolicy, setReturnPolicy] = useState();
   const [receivePriceWholesale, setReceivePriceWholesale] = useState();

   const [retailPrice, setRetailPrice] = useState();
   const [merchantPrice, setMerchantPrice] = useState();
   const [retailCommission, setRetailCommission] = useState();

   const [discountPrice, setDiscountPrice] = useState();
   const [processing, setProcessing] = useState(false);
   const [discountPercentage, setDiscountPercentage] = useState();
   const [selectedTags, setSelectedTags] = useState([]);
   const [selectedVehicles, setSelectedVehicles] = useState([]);
   const [selectedCategories, setSelectedCategories] = useState([]);
   const [stockAvailable, setStockAvailable] = useState();
   const [priceWholesale, setPriceWholesale] = useState();
   const [partName, setPartName] = useState();
   const [weight, setWeight] = useState();
   const [partDesc, setPartDesc] = useState();
   const [oem, setOem] = useState();
   const [partNumber, setPartNumber] = useState();
   const [brandsData, setBrandsData] = useState([]);
   const [selectedBrand, setSelectedBrand] = useState();
   const [vehiclesData, setVehiclesData] = useState([]);
   const [categoriesData, setCategoriesData] = useState([]);
   const [merchantsData, setMerchantsData] = useState([]);
   const [selectedMerchant, setSelectedMerchant] = useState();

   const [partCondition, setPartCondition] = useState();
   const [shippingMode, setShippingMode] = useState();

   const [variantList, setVariantList] = useState([]);
   const [variantListData, setVariantListData] = useState([]);
   const [specificationList, setSpecificationList] = useState([]);
   const [specificationListData, setSpecificationListData] = useState([]);
   const [sideBarStatus, setSideBarStatus] = useState(false)
   const [sideBarDiscountStatus, setSideBarDiscountStatus] = useState(false)

   const [sideBarWholesale, setSideBarWholesale] = useState(false)
   const [coverImageIndex, setCoverImageIndex] = useState(-1)
   const [isMerchantsLoading, setIsMerchantsLoading] = useState(false)
   const refFileInput = React.createRef();

   const [options, setOptions] = useState([]);
   const [page, setPage] = useState(1);
   const [perPage, setPerPage] = useState(100)
   const [hasMore, setHasMore] = useState(true);
   const [isLoading, setIsLoading] = useState(false);
   const [partType, setPartType] = useState();

   const partTypeChanged = (event) => {
      setPartType(event.target.value);
   };

   const loadOptions = async page => {
      const params = {
         "page": page,
         "per_page": perPage,
      }

      const searchParams = new URLSearchParams(params).toString();
      const response = await makeAuthenticatedRequest(`/oms/merchants/?${searchParams}`);
      const options = await response.json();


      const formattedOptions = options.results.map(option => ({
         ...option,
         value: option.id,
         label: option.merchant_name,
      }));

      return { options: formattedOptions };

      // return options;
   };


   const loadMoreOptions = async () => {
      if (!hasMore || isLoading) {
         return;
      }

      setIsLoading(true);

      const newOptions = await loadOptions(page);
      const allOptions = [...options, newOptions];

      setOptions(allOptions);
      setPage(page + 1);
      setHasMore(newOptions.length === 16);
      setIsLoading(false);
   };

   const fetchMerchants = async (inputValue) => {
      let data = []
      const params = { s: inputValue ? inputValue : "" }
      const searchParams = new URLSearchParams(params).toString();
      setIsMerchantsLoading(true)
      try {
         const response = await axiosCustom.get(`${MERCHANTS_URL}?${searchParams}`);
         data = response?.data
      } catch (err) {
         toast.error("Something went wrong");
      } finally {
         setIsMerchantsLoading(false)
      }
      return data
   }

   useEffect(() => {
      loadMoreOptions();
   }, [page]);



   const fetchCategories = () => {
      makeAuthenticatedRequest(`/car/part/categories/`)
         .then((response) => response.json())
         .then((data) => {
            setCategoriesData(data);
            return []
         });
   }

   const fetchBrands = () => {
      makeAuthenticatedRequest(`/car/part/brands/`)
         .then((response) => response.json())
         .then((data) => {
            setBrandsData(data);
            return []
         });
   }


   const createBrand = async () => {
      const params = { name: selectedBrand.value }
      return makeAuthenticatedRequest(`/car/part/brands/`, "POST", JSON.stringify(params))
         .then((response) => response.json())
         .then((data) => {
            return data
         });
   }

   const loadVehicleOptions = (inputValue) =>
      new Promise(async (resolve) => {
         const params = { "s": inputValue }
         const searchParams = new URLSearchParams(params).toString();
         const response = await makeAuthenticatedRequest(`/car/part/vehicles/?${searchParams}`);
         const data = await response.json();
         resolve(data);
      });


   useEffect(() => {
      fetchCategories();
      fetchBrands();
      fetchMerchants()
   }, [])

   // const handleDiscountPrice = (e) => {
   //    if(e.target.value > retailPrice){
   //       setDiscountPrice('')
   //    }else {

   //       setDiscountPrice(discountPrice)
   //    }

   // }

   // useEffect(() => {
   //    if (discountPrice > 0) {

   //       const discPer = truncateNumber(((retailPrice - discountPrice) / retailPrice) * 100)
   //       setDiscountPercentage(discPer)
   //    } else {
   //       setDiscountPercentage('')
   //    }
   //    if (discountPrice === undefined || discountPrice === '') {
   //       setReceivePrice(Math.ceil(truncateNumber(retailPrice - (retailPrice * commission / 100))))
   //    }
   //    if (discountPrice > 0 && retailPrice > 0) {
   //       setReceivePrice(Math.ceil(truncateNumber(discountPrice - (discountPrice * commission / 100))))
   //    }
   // }, [retailPrice, discountPrice])

   useEffect(() => {

      setReceivePriceWholesale(Math.ceil(truncateNumber(priceWholesale - (priceWholesale * wholesaleCommission / 100))))
   }, [priceWholesale])

   const sendToServer = async () => {
      setProcessing(true)

      let imageUrls
      if (croppedImages.length > 0) {
         imageUrls = await handleFilesUploadOnFirebaseStorage(croppedImages, selectedMerchant.id)
      } else {
         imageUrls = null
      }
      let brand = null
      if (selectedBrand != null) {
         if (selectedBrand.__isNew__ == undefined) {
            brand = selectedBrand.value
         } else {
            const brandDetails = await createBrand();
            if (brandDetails != null && brandDetails.id != null) {
               brand = brandDetails.id
            }
         }
      }

      let merchant = null
      if (selectedMerchant != null) {
         merchant = selectedMerchant.value
      }
      const tags = selectedTags.map(tag => tag.value)

      let categories = []
      if (selectedCategories) {
         selectedCategories.map((category) => {
            categories.push(category.value)
         })
      }
      let vehicles = []
      if (selectedVehicles != null && selectedVehicles.length > 0) {
         selectedVehicles.map((vehicle) => {
            vehicles.push(vehicle.value)
         })
      }
      let variations = []
      if (variantListData !== null && variantListData.length > 0) {
         variantListData.map((variation) => {
            const options = []
            if (variation.options != null && variation.options.length > 0) {
               variation.options.map((option) => (
                  options.push(option.value)
               ))
               variations.push({ name: variation.name.value, options: options })
            }
         })
      }
      let specifications = []
      if (specificationListData !== null && specificationListData.length > 0) {
         specificationListData.map((spec) => {
            if (
               spec.name != null &&
               spec.name.value != null &&
               spec.name.value.trim().length > 0 &&
               spec.value != null &&
               spec.value.value != null &&
               spec.value.value.trim().length > 0
            ) {
               specifications.push({ name: spec.name.value, value: spec.value.value })
            }
         })
      }
      const params = {
         oem: oem,
         sku: makeid(),
         brand: brand,
         name: partName,
         tags: tags,
         type: partType,
         merchant: merchant,
         vehicles: vehicles,
         variations: variations,
         categories: categories,
         wholesale_moq: moq,
         description: partDesc,
         part_number: partNumber,
         condition: partCondition,
         return_policy: returnPolicy,
         price: Math.ceil(truncateNumber(retailPrice)),
         merchant_price: Math.ceil(truncateNumber(merchantPrice))
      }
      if (weight) {
         params.weight = weight
      }

      if (shippingMode) {
         params.shipping_mode = shippingMode
      }

      if (parseInt(discountPrice) > parseInt(retailPrice)) {
         toast.error("Discount price cannot be greater than retail price")

      } else {

         const dPrice = truncateNumber(discountPrice)
         if (dPrice && !isNaN(dPrice)) {
            params.discount_price = Math.ceil(dPrice)
         }
      }
      const wPrice = truncateNumber(priceWholesale)
      if (wPrice && !isNaN(wPrice)) {
         params.wholesale_price = Math.ceil(wPrice)
      }
      const sAvail = Number(stockAvailable)
      if (sAvail && !isNaN(sAvail)) {
         params.stock_available = sAvail
      }

      if (imageUrls != null && imageUrls.length > 0) {
         params.thumbnail_urls = imageUrls
         if (coverImageIndex > -1 && coverImageIndex <= imageUrls.length) {
            params.cover_image_url = imageUrls[coverImageIndex]
         } else {
            params.cover_image_url = imageUrls[imageUrls.length - 1]
         }
      }
      if (specifications.length > 0) {
         params.specifications = specifications
      }

      return makeAuthenticatedRequest(`/oms/inventory/add/`, "POST", JSON.stringify(params))
         .then((response) => response.json())
         .then((data) => {
            toast.success("Part succesfully added")
            setProcessing(false);
            navigate(ENDPOINT_INVENTORY)
         }).catch((error) => {
            console.error('error', error)
            toast.error("Something went wrong could not add part")
            setProcessing(false);
         }).finally(() => {
            setProcessing(false);
         });
   };
   const handleSubmit = async (event, endpoint) => {
      event.preventDefault()
      if (!selectedMerchant || !selectedMerchant?.value) {
         toast.error("Select a valid merchant")
         return
      }

      if (partName == null || partName.trim().length < 1) {
         toast.error("Enter a valid part name")
         return
      }
      if (partDesc == null || partDesc.trim().length < 1) {
         toast.error("Enter a valid part description")
         return
      }

      if (partType == null || partType.trim().length < 1) {
         toast.error("Select a valid part type")
         return
      }

      if (partCondition == null || partCondition.trim().length < 1) {
         toast.error("Select a valid part condition")
         return
      }

      if (!retailPrice || !truncateNumber(retailPrice)) {
         toast.error("Enter a valid retail price")
         return
      }

      if (!merchantPrice || !truncateNumber(merchantPrice)) {
         toast.error("Enter a valid merchant price")
         return
      }

      if (!stockAvailable || !Number(stockAvailable)) {
         toast.error("Enter available stock")
         return
      }
      if (parseInt(discountPrice) > parseInt(retailPrice)) {
         toast.error("Discount price cannot be greater than retail price")
         return

      }
      const accountObj = new AccountObj();
      const user = auth.currentUser;

      setProcessing(true)


      if (user) {
         await sendToServer(endpoint);
      } else {
         await getFirebaseTokenThenSignin()
            .then(() => {
               sendToServer();
            })
      }
   }


   const components = {
      DropdownIndicator: null,
   };
   const [croppedImage, setCroppedImage] = useState(null)
   const [croppedImages, setCroppedImages] = useState([])
   const onFileChange = async (e) => {
      if (e.target.files && e.target.files.length > 0) {
         const newCroppedImages = []
         let lastCroppedImage = null;
         Array.from(e.target.files).map(async (file) => {
            const result = await compresedImage(file)
            lastCroppedImage = result
            newCroppedImages.push(lastCroppedImage)
            setCroppedImage(lastCroppedImage)
            setCroppedImages([...croppedImages, ...newCroppedImages])
         })
      }
   }

   useEffect(() => {
      if (croppedImage && croppedImages) {
         croppedImages.map((crImage, index) => {
            if (croppedImage == crImage) {
               setCoverImageIndex(index)
            }
         })
      }
   }, [croppedImage])

   const handleCancel = () => {
      navigate(ENDPOINT_INVENTORY)
   }
   const handleSpecificationListChangeName = (e, index) => {
      const list = [...specificationListData];
      list[index].name = e;
      setSpecificationListData(list);
   };
   const handleSpecificationListChangeValue = (e, index) => {
      const list = [...specificationListData];
      list[index].value = e;
      setSpecificationListData(list);
   };
   const handleVariantListChangeName = (e, index) => {
      const list = [...variantListData];
      list[index].name = e;
      setVariantListData(list);
   };
   const handleVariantListChangeOptions = (e, index) => {
      const list = [...variantListData];
      list[index].options = e;
      setVariantListData(list);
   };
   // handle click event of the Remove button
   const handleVariantListRemoveClick = index => {
      const list = [...variantList];
      list.splice(index, 1);
      setVariantList(list);
   }
   const handleSpecificationListRemoveClick = index => {
      const list = [...specificationList];
      list.splice(index, 1);
      setSpecificationList(list);
   }
   const handleImageRemoveClick = index => {
      const lastImageIndex = croppedImages.length - 2;
      const list = [...croppedImages];
      list.splice(index, 1);
      setCroppedImages(list);
      if (lastImageIndex < 0) {
         setCroppedImage(null)
      } else {
         setCroppedImage(croppedImages[lastImageIndex])
      }
   }
   const handleImageClick = (index) => {
      setCroppedImage(croppedImages[index])
   }
   // handle click event of the Add button
   const handleVariantListAddClick = () => {
      setVariantList([...variantList, { name: null, options: [] }]);
      setVariantListData([...variantListData, { name: null, options: [] }]);
   }
   // handle click event of the Add button
   const handleSpecificationListAddClick = () => {
      setSpecificationList([...specificationList, { name: null, value: [] }]);
      setSpecificationListData([...specificationListData, { name: null, value: [] }]);
   }
   const partCondtionChanged = (event) => {
      setPartCondition(event.target.value);
   }
   const shippingModeChanged = (event) => {
      setShippingMode(event.target.value);
   }

   const updateRetailPriceCommission = (value) => {
      if (value) {
         const comm = parseFloat(value)
         const mPrice = parseFloat(merchantPrice)
         const newPrice = (mPrice * ((100 + comm) / 100))
         setRetailPrice(parseInt(newPrice))
         setRetailCommission(comm)
      } else {
         setRetailCommission(value)
      }
   }

   const updateRetailPrice = (value) => {
      if (value) {
         const rPrice = parseInt(value)
         if (merchantPrice && parseInt(merchantPrice) > 0) {
            const rCommission = parseFloat((rPrice / merchantPrice) * 100) - 100
            setRetailCommission(truncateNumber(rCommission))
         }
         setRetailPrice(rPrice)
      } else {
         setRetailPrice(value)
      }
   }
   const [error, setError] = useState("");

   const updateMerchantPrice = (value) => {
      if (value) {
         const mPrice = parseInt(value);

         if (mPrice > 0) {
            if (retailPrice && parseInt(retailPrice) > 0) {
               const rCommission = parseFloat((retailPrice / mPrice) * 100) - 100;
               setRetailCommission(truncateNumber(rCommission));
            }
            setMerchantPrice(mPrice);
            setError("");
         } else {
            setError("Invalid price input. Enter a value greater than zero.");
         }
      } else {
         setMerchantPrice(value);
         setError(""); 
      }
   };

   const navigate = useNavigate();



   return (
      <>
         <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">

            {false ?
               <ReactLoading type="bubbles" color="#030A1A" height={50} width={60} />
               :
               <div className="flex flex-col">

                  <div className="pl-2 md:pl-28 pt-1  flex flex-row md:grid md:grid-cols-5 gap-2 border-b pb-2 w-full bg-white z-50">
                     <div className="col-span-1">
                        <h2 className='font-satoshi font-semibold text-xs md:text-lg'>Add a part</h2>
                     </div>
                     <div className="col-span-1 "></div>
                     <div className="col-span-1 flex flex-row gap-2 items-end"></div>
                     {
                        processing ?
                           <div className="flex flex-row gap-2 md:col-span-2">
                              <button className='py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' onClick={handleCancel} ><span className='text-center' >Processing</span> <ReactLoading type="bubbles" color="#030A1A" height={10} width={30} className="inline-block align-middle" /></button>
                           </div>
                           :
                           <div className="flex flex-row gap-2 md:col-span-2">
                              <button className='py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' onClick={handleCancel} ><span className='text-center' >Cancel</span></button>
                              <button className='py-1 md:py-2 px-1 md:px-3 md:w-12/12 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' onClick={(e) => handleSubmit}><span className='text-center'>Save and add another</span></button>
                              <button className='py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-xs md:text-sm' onClick={(e) => handleSubmit(e, ENDPOINT_INVENTORY)}><span className='text-center'>Save</span></button>
                           </div>
                     }
                  </div>
                  <div className='flex flex-col md:flex-row md:w-[90%] gap-10 pl-2 md:pl-20 pt-1' >
                     <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1 hide-scrollbar overflow-y-scroll">
                        <div className="w-full flex flex-col gap-2">
                           <p className='text-[#818490] font-satoshi text-md font-semibold'>Part name</p>
                           <input
                              onChange={e => setPartName(e.target.value)}
                              required
                              value={partName}
                              className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                              placeholder='e.g Brembo ceramic brake calipers 16 inch'
                           />
                           <div className="flex flex-col gap-1 w-full mt-2 mb-2">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Merchant</p>

                              <AsyncSelect
                                 value={selectedMerchant}
                                 className='w-full outline-none'
                                 loadOptions={fetchMerchants}
                                 placeholder="Search"
                                 isLoading={isMerchantsLoading}
                                 onChange={e => {
                                    setSelectedMerchant(e)
                                    setCommission(Number(e.merchant_commission))
                                    setWholesaleCommission(Number(e.merchant_wholesale_commission))
                                 }}
                              />
                           </div>
                           <div>
                              <div>
                                 {croppedImage ?
                                    <img src={URL.createObjectURL(croppedImage)}
                                       className='bg-white rounded-lg mt-4 mb-2 h-72 w-full border object-contain' alt=""
                                    />
                                    :
                                    <div>
                                       <input type="file"
                                          name="file"
                                          id="files"
                                          ref={refFileInput}
                                          accept="image/*"
                                          multiple
                                          onChange={onFileChange}
                                          className='hidden' />
                                       <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                                          <img src={ic_plus_big} className='h-20 bg-gray-300' alt="" />
                                       </label>
                                    </div>
                                 }
                              </div>
                              <div className="flex flex-row gap-2">
                                 {croppedImages.map((image, i) => (
                                    <div key={i} className='flex flex-row'>
                                       <div class="mt-1 mr-1">
                                          <img src={URL.createObjectURL(image)}
                                             overflow-hidden rounded-lg object-contain h-20 w-20 border border-gray-200
                                             className={image == croppedImages[coverImageIndex] ? 'bg-white rounded-lg w-full border object-contain h-20 w-20 border-4 border-teal-400' : 'bg-white rounded-lg w-full border object-contain h-20 w-20 border border-gray-200'}
                                             alt="" onClick={() => handleImageClick(i)} />
                                       </div>
                                       <span className='text-red-500 p-2 hover:cursor z-50 -ml-8 -mt-2 h-2' onClick={() =>
                                          handleImageRemoveClick(i)}>
                                          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg">
                                             <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                          </svg>
                                       </span>
                                    </div>
                                 ))}
                                 {croppedImage && (
                                    <label htmlFor="file-upload" class="relative cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none ">
                                       <div class="mt-1 flex justify-center rounded-md p-6">
                                          <div class="space-y-1 text-center">
                                             <div class="flex text-sm text-gray-600">
                                                <span className='font-satoshi bg-gray-300'>
                                                   <img src={ic_plus_big} className=' h-7 w-7 bg-gray-300' alt="" />
                                                </span>
                                                <input ref={refFileInput} id="file-upload" name="file-upload" multiple type="file" className="sr-only" onChange={onFileChange} accept="image/*" />
                                             </div>
                                          </div>
                                       </div>
                                    </label>
                                 )}
                              </div>
                           </div>
                           <div className="rounded-md mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Part description</p>
                              <textarea onChange={e => setPartDesc(e.target.value)} value={partDesc} className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md font-semibold rounded' rows={6} placeholder="Enter a description about the part"></textarea>
                           </div>
                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Detailed specifications</p>
                              <div className="flex flex-col">

                                 <div className="flex flex-row justify-between mt-2 mb-2">
                                    <span>Part number</span>
                                    <input onChange={e => setPartNumber(e.target.value)} value={partNumber} className='p-1 border border-gray-200 rounded-lg w-7/12 w-full text-[#19223B] font-satoshi text-md rounded' placeholder='Part number' />
                                 </div>
                                 <div className="flex flex-row justify-between mt-2 mb-2">
                                    <span>Brand/Manufacturer</span>
                                    <CreatableSelect
                                       className='p-1 rounded-lg w-7/12 w-full text-[#19223B] font-satoshi text-md rounded'
                                       placeholder="Brand"
                                       isClearable
                                       name="brand"
                                       options={brandsData}
                                       onChange={e => setSelectedBrand(e)} />
                                 </div>
                              </div>
                              {specificationList.map((variant, i) => (
                                 <div className='flex flex-row justify-between mt-2 mb-2'>
                                    <CreatableSelect
                                       components={components}
                                       onChange={e =>
                                          handleSpecificationListChangeName(e, i)}
                                       isClearable
                                       placeholder="Attribute name; type something and press enter..."
                                       className='w-4/12 mr-2'
                                    />
                                    <CreatableSelect
                                       className='p-1 rounded-lg w-7/12 w-full text-[#19223B] font-satoshi text-md rounded'
                                       components={components}
                                       onChange={e => handleSpecificationListChangeValue(e, i)}
                                       isClearable
                                       placeholder="Attribute value; type something and press enter..."
                                    />
                                    <span className='text-red-500 p-2 hover:cursor' onClick={() =>
                                       handleSpecificationListRemoveClick(i)}>
                                       <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                       </svg>
                                    </span>
                                 </div>
                              ))}
                              <div className="flex flex-row  flex-wrap mt-2 mb-2">
                                 <div className="pr-3 pt-2 pb-2 pl-3 border border-gray-200 m-1  cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none">
                                    <span className='font-bold text-black' onClick={handleSpecificationListAddClick}><span className='mr-1 '>&#43;</span>Add a specification</span>
                                 </div>
                              </div>
                           </div>


                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>
                                 Part Type *
                              </p>
                              <div>
                                 <div className="flex flex-row mt-2">
                                    <input
                                       checked={partType === PartType.OEM}
                                       onChange={partTypeChanged}
                                       type="radio"
                                       value={PartType.OEM}
                                       className="mr-1"
                                    />
                                    <span>OEM</span>
                                 </div>

                                 <div className="flex flex-row mt-2">
                                    <input
                                       checked={partType === PartType.AFTER_MARKET}
                                       onChange={partTypeChanged}
                                       type="radio"
                                       value={PartType.AFTER_MARKET}
                                       className="mr-1"
                                    />
                                    <span>Aftermarket</span>
                                 </div>
                              </div>
                           </div>

                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Part Condition</p>
                              <div>
                                 <div className="flex flex-row mt-2">
                                    <input
                                       checked={partCondition === PartCondition.BRAND_NEW}
                                       onChange={partCondtionChanged}
                                       name="part_condition" type="radio" value={PartCondition.BRAND_NEW} className="mr-1" />
                                    <span>Brand new</span>
                                 </div>
                                 <div className="flex flex-row mt-1">
                                    <input
                                       checked={partCondition === PartCondition.REFURBISHED}
                                       onChange={partCondtionChanged}
                                       name="part_condition" type="radio" value={PartCondition.REFURBISHED} className="mr-1" />
                                    <span>Refurbished</span>
                                 </div>
                                 <div className="flex flex-row  mt-1">
                                    <input
                                       checked={partCondition === PartCondition.SECOND_HAND}
                                       onChange={partCondtionChanged}
                                       name="part_condition" type="radio" value={PartCondition.SECOND_HAND} className="mr-1" />
                                    <span>Used</span>
                                 </div>
                              </div>
                           </div>
                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-md font-satoshi font-semibold'>Categories</p>
                              <div className="flex flex-col">
                                 <Select
                                    placeholder="Search or select a category"
                                    isMulti
                                    name="categories"
                                    options={categoriesData}
                                    onChange={e => setSelectedCategories(e)} />
                              </div>
                           </div>
                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-md font-satoshi font-semibold'>Tags</p>
                              <div className="flex flex-col">
                                 <CreatableSelect
                                    components={components}
                                    isClearable
                                    isMulti
                                    placeholder="Type something and press enter..."
                                    onChange={e =>
                                       setSelectedTags(e)}
                                 />
                              </div>
                           </div>
                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-md font-satoshi font-semibold'>Vehicle Compatibility</p>
                              <div className="flex flex-col">
                                 <AsyncSelect
                                    cacheOptions
                                    loadOptions={loadVehicleOptions}
                                    placeholder="Search or select a category"
                                    isMulti
                                    name="vehicles"
                                    onChange={e => setSelectedVehicles(e)}
                                 />
                              </div>
                           </div>
                           <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-md font-satoshi font-semibold'>Variants</p>
                              <div className="flex flex-col">
                                 {variantList.map((variant, i) => (
                                    <div className='flex flex-row  flex-wrap mt-2 mb-2'>
                                       <CreatableSelect
                                          components={components}
                                          onChange={e =>
                                             handleVariantListChangeName(e, i)}
                                          isClearable
                                          placeholder="Attribute name"
                                          className='w-4/12 mr-2'
                                       />
                                       <CreatableSelect
                                          components={components}
                                          onChange={e =>
                                             handleVariantListChangeOptions(e, i)}
                                          className='w-7/12'
                                          isClearable
                                          isMulti
                                          placeholder="Attribute values; type something and press enter..."
                                       />
                                       <span className='text-red-500 p-2 hover:cursor' onClick={() =>
                                          handleVariantListRemoveClick(i)}>
                                          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                                             <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                          </svg>
                                       </span>
                                    </div>
                                 ))}
                                 <div className="flex flex-row  flex-wrap mt-2 mb-2">
                                    <div className="pr-3 pt-2 pb-2 pl-3 border border-gray-200 m-1  cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none">
                                       <span className='font-bold text-black' onClick={handleVariantListAddClick}><span className='mr-1 '>&#43;</span>Add a variant</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="rounded-md mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Return policy</p>
                              <textarea onChange={e => setReturnPolicy(e.target.value)} value={returnPolicy} className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md font-semibold rounded' rows={6} placeholder="Describe your return policy"></textarea>
                           </div>
                           {false && <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                              <p className='text-[#818490] font-satoshi text-md font-semibold'>Shipping Mode</p>
                              <div>
                                 <div className="flex flex-row mt-2">
                                    <input
                                       checked={shippingMode === ShippingMode.BIKE}
                                       onChange={shippingModeChanged}
                                       name="shipping_mode" type="radio" value={ShippingMode.BIKE} className="mr-1" />
                                    <span>Bike</span>
                                 </div>
                                 <div className="flex flex-row mt-2">
                                    <input
                                       checked={shippingMode === ShippingMode.VAN_TRUCK}
                                       onChange={shippingModeChanged}
                                       name="shipping_mode" type="radio" value={ShippingMode.VAN_TRUCK} className="mr-1" />
                                    <span>Van/Truck</span>
                                 </div>
                              </div>
                           </div>}


                           <div className={`flex flex-col w-full gap-2 border rounded-md p-2`}>
                              <p className='font-semibold text-[#333B51] font-satoshi'>Mode of transport</p>
                              <div className="flex flex-row gap-1">
                                 <p className='font-satoshi text-[14px] '>What's the recommended way to transport this item?</p>

                              </div>
                              <div className="flex md:grid flex-col md:grid-cols-2 gap-3">
                                 <div className={`col-span-1 flex flex-row ${shippingMode === ShippingMode.BIKE ? 'border border-[#333B51]' : 'border'} rounded-md px-2 py-2  gap-1`}>

                                    <label for="bike" class="relative w-full flex gap-1 cursor-pointer outline-none bg-white font-medium">
                                       <img src="/cdn/images/bike.svg" alt="" />
                                       <input
                                          onChange={shippingModeChanged}
                                          value={ShippingMode.BIKE}
                                          id="bike" name="shipping" type="radio" checked={shippingMode === ShippingMode.BIKE} className="outline-none sr-only" />
                                       <p htmlFor="" className={` py-2 font-satoshi text-[16px] ${shippingMode === ShippingMode.BIKE ? 'text-[#333B51]' : 'text-gray-4'}`}>Motorcycle</p>
                                    </label>
                                    {/* <input type="radio" className='bg-white border border-white py-2 ' placeholder='Motorcycle' /> */}



                                 </div>
                                 <div className={`col-span-1 flex flex-row ${shippingMode === ShippingMode.VAN_TRUCK ? 'border border-[#333B51]' : 'border'} rounded-md px-2 py-2 border gap-1`}>

                                    <label for="van" class="relative w-full flex  gap-1 cursor-pointer outline-none bg-white font-medium">
                                       <img src="/cdn/images/van.svg" alt="" />
                                       <input
                                          onChange={shippingModeChanged}
                                          id="van" value={ShippingMode.VAN_TRUCK} name="shipping" type="radio" checked={shippingMode === ShippingMode.VAN_TRUCK} className="outline-none sr-only" />
                                       <p htmlFor="" className={`${shippingMode === ShippingMode.VAN_TRUCK ? 'text-[#333B51]' : 'text-gray-4'} py-2 font-satoshi text-[16px]`}>Van</p>
                                    </label>


                                 </div>

                              </div>
                           </div>

                           {false &&
                              <>
                                 <p className='text-[#818490] font-satoshi text-md font-semibold'>Estimated weight</p>
                                 <input
                                    onChange={e => setWeight(e.target.value)}
                                    required
                                    value={weight}
                                    className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                                    placeholder='Estimated weight'
                                 />
                              </>
                           }


                           <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                              <p className='font-semibold text-[#333B51] font-satoshi'>Estimated weight</p>
                              <div className="flex md:grid flex-col md:grid-cols-4 gap-2">
                                 <div className="col-span-1 flex flex-col gap-6">
                                    <div className={`border flex flex-row  gap-1 rounded-md p-2 `}>
                                       <input name='weight' type="radio" onChange={e => setWeight(5)} className={` accent-[#333B51]`} />
                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mt-1">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                       </svg>
                                       <p className='font-satoshi text-[#333B51] font-medium text-[16px]'>5kg</p>
                                    </div>

                                 </div>
                                 <div className="col-span-1 flex flex-col gap-2">
                                    <div className={`border flex flex-row  gap-1 rounded-md p-2 `}>
                                       <input name='weight' type="radio" onChange={e => setWeight(20)} className={` accent-[#333B51] `} />
                                       <p className='font-satoshi text-[#333B51] font-medium text-[16px]'>5-20kg</p>
                                    </div>


                                 </div>
                                 <div className="col-span-1 flex flex-col gap-2">
                                    <div className={` border flex flex-row  gap-1 rounded-md p-2 `}>
                                       <input onChange={e => setWeight(45)} name='weight' type="radio" className={` accent-[#333B51]`} />
                                       <p className='font-satoshi text-[#333B51] font-medium text-[16px]'>20-50 kg</p>
                                    </div>
                                 </div>
                                 <div className="col-span-1 flex flex-col gap-2">
                                    <div className={`border flex flex-row  gap-1 rounded-md p-2 `}>
                                       <input onChange={e => setWeight(50)} name='weight' type="radio" className={` accent-[#333B51]`} />
                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mt-1">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                       </svg>

                                       <p className='font-satoshi text-[#333B51] font-medium text-[16px]'> 50 kg</p>
                                    </div>
                                 </div>

                              </div>
                           </div>

                           <div className="grid py-4 mt-4">
                              {
                                 processing ?
                                    <div className="flex flex-row gap-2 md:col-span-2">
                                       <button className='py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' onClick={handleCancel} ><span className='text-center' >Processing</span> <ReactLoading type="bubbles" color="#030A1A" height={10} width={30} className="inline-block align-middle" /></button>
                                    </div>
                                    :
                                    <div className="flex gap-2 w-full flex-row-reverse col-span-2">
                                       <button title="save" className='py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-sm' onClick={(e) => handleSubmit(e, ENDPOINT_INVENTORY)}>Save</button>
                                       <button className='py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm' onClick={handleSubmit}><span className='text-center'>Save and add another</span></button>
                                       <button className='py-1 md:py-2 px-1 md:px-4 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm' onClick={handleCancel}><span className='text-center' >Cancel</span></button>
                                    </div>
                              }
                           </div>
                        </div>
                     </div>
                     <div className="sticky top-28 pt-4 flex flex-col w-full pr-2 md:pr-0 md:w-[20%] flex-[1] mb-3">
                        <p className='text-[#19223B] font-satoshi text-md font-semibold'>Price Details</p>
                        <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">

                           <div className={`flex-col `}>

                              <div className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Buying/Merchant Price</label>
                                 <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                       type="number" min="1" max="999999999"
                                       onChange={e => updateMerchantPrice(e.target.value)} value={merchantPrice}
                                       className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                                       <span className="text-gray-500">KSH</span>
                                    </div>
                                 </div>
                              </div>

                              <div className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Commission (%)</label>
                                 <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                       type="number"
                                       onChange={e => updateRetailPriceCommission(e.target.value)}
                                       value={retailCommission}
                                       className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                                       <span className="text-gray-500">%</span>
                                    </div>
                                 </div>
                              </div>


                              <div className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Retail Price</label>

                                 <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                       type="number" min="0" max="999999999"
                                       onChange={e => updateRetailPrice(e.target.value)} value={retailPrice}
                                       className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                                       <span className="text-gray-500">KSH</span>
                                    </div>
                                 </div>
                              </div>

                              <div className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Units in stock</label>
                                 <div className="flex flex-row ">
                                    <input
                                       onChange={e => setStockAvailable(e.target.value)}
                                       value={stockAvailable} type="number" name=""
                                       className='w-full p-1.5 border border-gray-200 bg-gray-200  outline-none'
                                    />
                                 </div>
                              </div>

                           </div>
                        </div>

                        <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">
                           <div onClick={() => setSideBarDiscountStatus(open => !open)}>
                              <div className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Discount price</label>
                                 <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                       type="number" min="0" max="999999999"
                                       onChange={e => setDiscountPrice(e.target.value)} value={discountPrice}
                                       className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                                       placeholder="13,499"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                                       <span className="text-gray-500">KSH</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {sideBarDiscountStatus &&
                              <div className={`flex-col `}>
                                 <div className="mt-2 mb-2">
                                    <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Discount</label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                       <input
                                          readOnly
                                          disabled
                                          type="number" min="0" max="100"
                                          onChange={e => setDiscountPercentage(e.target.value)} value={discountPercentage}
                                          className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                                          placeholder="10"
                                       />
                                       <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                                          <span className="text-gray-500">%</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           }
                        </div>

                        <div className="mt-2 mb-2 rounded-md border border-gray-300 p-2 bg-white">
                           <div className="flex flex-col">
                              <div onClick={() =>
                                 setSideBarWholesale(open => !open)} className="mt-2 mb-2">
                                 <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Wholesale price</label>
                                 <div className="relative mt-1 rounded-md shadow-sm">
                                    <input
                                       type="number" min="0" max="999999999"
                                       onChange={e => setPriceWholesale(e.target.value)} value={priceWholesale}
                                       className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                                       placeholder="13,499"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                                       <span className="text-gray-500">KSH</span>
                                    </div>
                                 </div>
                              </div>
                              {sideBarWholesale &&
                                 <>
                                    <div className="mt-2 mb-2">
                                       <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Minimum order quantity</label>
                                       <div className="relative ">
                                          <input onChange={e => setMoq(e.target.value)} value={moq} type="text" name="" className='w-full p-1.5 border border-gray-200 bg-gray-200  outline-none' placeholder="0" />
                                          <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                                             <span className="">units</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mt-2 mb-2">
                                       <label htmlFor="retail" className="block text-sm font-satoshi text-gray-700">Amount you will receive</label>
                                       <div className="relative mt-1 rounded-md shadow-sm">
                                          <input
                                             type="number" min="0" max="999999999"
                                             onChange={e => setReceivePriceWholesale(e.target.value)} value={receivePriceWholesale}
                                             className="w-full p-1.5 border border-gray-200 bg-[#79C38D33] outline-none appearance-none"
                                             placeholder="9,499.30"
                                          />
                                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                                             <span className="text-gray-500">KSH</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mt-1 mb-1">
                                       <p className='font-satoshi text-xs'>Sparepap takes an {wholesaleCommission}% commission on wholesale purchases above 50 units</p>
                                    </div>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         </div>



         <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
      </>
   )
}
export default InventoryAdd