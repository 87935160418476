import React, { useEffect, useRef, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { PartType } from "../constants";
import { ENDPOINT_INVENTORY } from "../routes";
import {
  formatNumber,
  formatNumberStr,
  getFirebaseTokenThenSignin,
  makeAuthenticatedRequest,
  makeid,
  truncateNumber,
} from "../utils";
import Compressor from "compressorjs";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { auth, storage } from "../firebaseUtils";
import { AccountObj } from "../utils";
import Avatar from "react-avatar";
import Moment from "react-moment";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";

export const compresedImage = (file) =>
  new Promise((resolve) => {
    new Compressor(file, {
      minWidth: 640,
      maxHeight: 640,
      mimeType: "image/webp",
      success(result) {
        resolve(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });

function InventoryEdit() {
  let { inventoryID } = useParams();
  const [processing, setProcessing] = useState(false);
  const [inventoryData, setInventoryData] = useState();

  const [addCorrectionNote, setAddCorrectionNote] = useState(false);

  const [sparepapCommission, setSparepapCommission] = useState();
  const [sparepapWholesaleCommission, setSparepapWholesaleCommission] =
    useState(8);

  const [openNotes, setOpenNotes] = useState(false);
  const [moq, setMoq] = useState();
  const [receivePrice, setReceivePrice] = useState();
  const [aftermarketNumber, setAftermarketNumber] = useState();

  const [returnPolicy, setReturnPolicy] = useState();
  const [brandsData, setBrandsData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [retailPrice, setRetailPrice] = useState();
  const [retailCommission, setRetailCommission] = useState();

  const [wholesalePrice, setWholesalePrice] = useState();

  const [discountPrice, setDiscountPrice] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsPrev, setSelectedTagsPrev] = useState([]);

  const [selectedSpecificationsPrev, setSelectedSpecificationsPrev] = useState(
    []
  );

  const [specificationList, setSpecificationList] = useState([]);
  const [specificationListData, setSpecificationListData] = useState([]);
  const [selectedVariationsPrev, setSelectedVariationsPrev] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedVehiclesPrev, setSelectedVehiclesPrev] = useState([]);
  const [selectedCategoriesPrev, setSelectedCategoriesPrev] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stockAvailable, setStockAvailable] = useState();
  const [sideBarDiscountStatus, setSideBarDiscountStatus] = useState(false);

  const [priceWholesale, setPriceWholesale] = useState();
  const [wholesaleCommission, setWholesaleCommission] = useState();
  const [receivePriceWholesale, setReceivePriceWholesale] = useState();

  const [partName, setPartName] = useState();
  const [partDesc, setPartDesc] = useState();
  const [oem, setOem] = useState();
  const [partType, setPartType] = useState();
  const [partNumber, setPartNumber] = useState();
  const [brand, setBrand] = useState();
  const [coverImage, setCoverImage] = useState();
  const [thumbnails, setThumbnails] = useState([]);

  const [weight, setWeight] = useState();
  const [shippingMode, setShippingMode] = useState();

  const [merchantPrice, setMerchantPrice] = useState();
  const [merchantWholesalePrice, setMerchantWholesalePrice] = useState();

  const [categoriesData, setCategoriesData] = useState([]);
  const [partCondition, setPartCondition] = useState();
  const [variantList, setVariantList] = useState([]);
  const [variantListData, setVariantListData] = useState([]);
  const [sideBarStatus, setSideBarStatus] = useState(false);
  const [sideBarWholesale, setSideBarWholesale] = useState(false);
  const [coverImageIndex, setCoverImageIndex] = useState(-1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const refFileInput = React.createRef();
  const [profit, setProfit] = useState();
  const [wholesaleProfit, setWholesaleProfit] = useState();

  const [expiryDate, setExpiryDate] = useState(null);
  const [error, setError] = useState("");

  // TAGS TWO START
  const [tags2, setTags2] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags2, setSelectedTags2] = useState([]);

  const getTags = async ({ params }) => {
    const queryParams = new URLSearchParams(params).toString();
    const url = `/oms/inventory/tags/?${queryParams}`;

    try {
      const response = await makeAuthenticatedRequest(url);

      if (response.status == 200) {
        const data = await response.json();
        const tags = data.results.map((tag) => ({
          value: tag.name,
          label: tag.name,
        }));
        return tags;
      } else {
        toast.error("Failed to fetch tags!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleSelectedTagsChange = (value) => {
    setSelectedTags2(value);
  };

  const createTagsTwoFocus = async () => {
    const tags = await getTags({ params: { per_page: 50 } });
    setTagOptions(tags);
  };

  const createPartTags = async ({ payload }) => {
    const requestPayload = JSON.stringify(payload);
    const url = "/oms/inventory/tag/";

    try {
      const response = await makeAuthenticatedRequest(
        url,
        "POST",
        requestPayload
      );

      if (response.status == 200) {
        toast.success("Part tags created successfully");
      } else {
        toast.error("Failed to create part tags!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleSubmitTags2 = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let payload = {};
    const tags = selectedTags2.map((tag) => tag?.value);
    const ids = [inventoryID];

    if (!(tags.length > 0)) {
      toast.error("Please select tags");
      setProcessing(false);
      return;
    }

    payload = { carpart_ids: ids, tags: tags };
    await createPartTags({ payload });
    setSelectedTags2([]);
    fetchInventory();
    setProcessing(false);
    return;
  };

  const removeTag = async ({ payload }) => {
    const requestPayload = JSON.stringify(payload);

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/inventory/untag/`,
        "POST",
        requestPayload
      );

      if (response.status == 200) {
        toast.success("Tag removed successfully");
      } else {
        toast.error("Failed to remove tag!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleRemoveTagClick = async (tag) => {
    const payload = { carpart_id: inventoryID, tag_id: tag?.id };
    await removeTag({ payload });
    fetchInventory();
  };
  // TAGS TWO END

  const partTypeChanged = (event) => {
    setPartType(event.target.value);
  };

  const buyerPrice = () => {
    if (
      discountPrice &&
      retailPrice &&
      !isNaN(parseFloat(discountPrice)) &&
      !isNaN(parseFloat(retailPrice)) &&
      parseFloat(discountPrice) > 0 &&
      parseFloat(discountPrice) < parseFloat(retailPrice)
    ) {
      return discountPrice;
    }
    return retailPrice;
  };

  const handleClickScroll = () => {
    setOpenNotes(true);
    setAddCorrectionNote(true);
    const element = document.getElementById("invnotes");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const brandsD = ["Denso", "brembo"];

  const loadVehicleOptions = (inputValue) =>
    new Promise(async (resolve) => {
      const params = { s: inputValue };
      const searchParams = new URLSearchParams(params).toString();
      const response = await makeAuthenticatedRequest(
        `/car/part/vehicles/?${searchParams}`
      );
      const data = await response.json();
      resolve(data);
    });

  const fetchCategories = () => {
    makeAuthenticatedRequest(`/car/part/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategoriesData(data);
        return [];
      });
  };
  const fetchBrands = () => {
    makeAuthenticatedRequest(`/car/part/brands/`)
      .then((response) => response.json())
      .then((data) => {
        setBrandsData(data);
        return [];
      });
  };

  useEffect(() => {
    fetchInventory();
    fetchCategories();
    fetchBrands();
  }, []);

  const fetchInventory = () => {
    makeAuthenticatedRequest(`/oms/inventory/${inventoryID}`)
      .then((response) => response.json())
      .then((data) => {
        setInventoryData(data);
        const date = new Date(data?.expiry_date);
        setExpiryDate(date);
      });
  };

  const handleFileUploadOnFirebaseStorage = async (uploadedImage) => {
    console.log(uploadedImage);
    const bucketName = `/merchant/${inventoryData.passport.id}/inventory`;
    if (uploadedImage === "" || uploadedImage === null) return "";
    const fileID = makeid();
    const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);
    await uploadBytesResumable(storageRef, uploadedImage);
    let downloadURL = null;
    await getDownloadURL(storageRef).then((fileURL) => (downloadURL = fileURL));
    return downloadURL;
  };

  const calculateProfit = () => {
    let mp = merchantPrice;
    if (!mp || isNaN(mp)) {
      mp = 0;
    } else {
      mp = parseFloat(mp);
    }

    let rp = buyerPrice();
    if (!rp || isNaN(rp)) {
      rp = 0;
    } else {
      rp = parseFloat(rp);
    }
    setProfit(truncateNumber(rp - mp, 0));

    let mwp = merchantWholesalePrice;
    if (!mwp || isNaN(mwp)) {
      mwp = 0;
    } else {
      mwp = parseFloat(mwp);
    }

    let rwp = wholesalePrice;
    if (!rwp || isNaN(rwp)) {
      rwp = 0;
    } else {
      rwp = parseFloat(rwp);
    }
    setWholesaleProfit(truncateNumber(rwp - mwp, 0));
  };

  const calculateSparepapCommission = () => {
    const bp = buyerPrice();
    if (
      merchantPrice &&
      bp &&
      !isNaN(parseFloat(merchantPrice)) &&
      !isNaN(parseFloat(bp))
    ) {
      const mc = (parseFloat(bp) - parseFloat(merchantPrice)) / parseFloat(bp);
      setSparepapCommission(truncateNumber(mc * 100, 2));
    } else {
    }

    const wbp = wholesalePrice;
    if (
      merchantWholesalePrice &&
      wbp &&
      !isNaN(parseFloat(merchantWholesalePrice)) &&
      !isNaN(parseFloat(wbp))
    ) {
      const mc =
        (parseFloat(wbp) - parseFloat(merchantWholesalePrice)) /
        parseFloat(wbp);
      setSparepapWholesaleCommission(truncateNumber(mc * 100, 2));
    }
  };

  useEffect(() => {
    if (inventoryData == null) return;
    setPartName(inventoryData?.name);
    setPartDesc(inventoryData?.description);
    setBrand(inventoryData?.brand);
    setOem(inventoryData?.oem);
    setWholesalePrice(inventoryData?.wholesale_price);
    setPartNumber(inventoryData?.part_number);
    setAftermarketNumber(inventoryData?.part_number);
    setPartCondition(
      inventoryData?.condition == null ? "" : inventoryData?.condition
    );
    setReturnPolicy(
      inventoryData?.returnPolicy == null ? "" : inventoryData?.returnPolicy
    );

    if (inventoryData?.merchant_price && inventoryData?.price) {
      setRetailCommission(
        truncateNumber(
          (parseInt(inventoryData?.price) /
            parseInt(inventoryData?.merchant_price)) *
            100 -
            100
        )
      );
    }

    if (inventoryData?.discountPrice > 0) {
      setDiscountPrice(inventoryData?.discountPrice);
    }
    if (inventoryData.wholesale_moq) {
      setMoq(truncateNumber(inventoryData.wholesale_moq, 0));
    }
    setWeight(inventoryData?.weight);
    setShippingMode(inventoryData?.shipping_mode);
    setSelectedBrand(inventoryData?.brand);
    if (inventoryData.price) {
      setRetailPrice(truncateNumber(inventoryData.price, 0));
    }
    if (inventoryData.merchant_price) {
      setMerchantPrice(truncateNumber(inventoryData.merchant_price, 0));
    }
    if (inventoryData.discount_price) {
      setDiscountPrice(truncateNumber(inventoryData.discount_price, 0));
    }

    if (inventoryData.merchant_price_wholesale) {
      setMerchantWholesalePrice(
        truncateNumber(inventoryData.merchant_price_wholesale, 0)
      );
    }

    if (inventoryData.wholesale_price) {
      setWholesalePrice(truncateNumber(inventoryData.wholesale_price, 0));
    }
    setSelectedTagsPrev(inventoryData?.tags);
    setPartType(inventoryData?.type);
    setStockAvailable(inventoryData?.stock_available);
    setSelectedCategoriesPrev(inventoryData?.categories);
    setSelectedVehiclesPrev(inventoryData?.vehicles);
    setSelectedVariationsPrev(inventoryData?.variations);
    let sImages = [];
    if (
      inventoryData?.thumbnail_urls &&
      inventoryData?.thumbnail_urls.length > 0
    ) {
      inventoryData?.thumbnail_urls.map((sImage, index) => {
        sImages.push({ image: sImage, isPrev: true });
      });
    }
    setSelectedImage({ image: inventoryData?.cover_image_url, isPrev: true });
    setCoverImage({ img: inventoryData?.cover_image_url });
    setThumbnails(inventoryData?.thumbnail_urls);
    setSelectedImages(sImages);
    setSelectedSpecificationsPrev(inventoryData?.specifications);
    setTags2(inventoryData?.tags2);
  }, [inventoryData]);

  const createBrand = async () => {
    const params = { name: selectedBrand.value };
    return makeAuthenticatedRequest(
      `/car/part/brands/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  };

  const onFileChange = async (e) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files);
      const newCroppedImages = [];
      let lastCroppedImage = null;
      Array.from(e.target.files).map(async (file) => {
        const newImage = await compresedImage(file);
        const result = { image: newImage, isPrev: false };
        lastCroppedImage = result;
        newCroppedImages.push(lastCroppedImage);
        console.log(result);
        setSelectedImage(lastCroppedImage);
        setSelectedImages([...selectedImages, ...newCroppedImages]);
      });
    }
  };

  useEffect(() => {
    if (selectedImage && selectedImages) {
      selectedImages.map((crImage, index) => {
        if (selectedImage == crImage) {
          setCoverImageIndex(index);
        }
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (
      discountPrice &&
      retailPrice &&
      parseInt(discountPrice) > 0 &&
      parseInt(retailPrice) > parseInt(discountPrice)
    ) {
      setDiscountPercentage(
        truncateNumber((retailPrice - discountPrice) / retailPrice) * 100
      );
    } else {
      setDiscountPercentage(null);
    }

    if (!discountPrice && !retailPrice) {
      setDiscountPercentage(null);
    }
  }, [retailPrice]);

  useEffect(() => {
    if (
      discountPrice &&
      retailPrice &&
      parseInt(retailPrice) > parseInt(discountPrice)
    ) {
      setDiscountPercentage(
        truncateNumber((retailPrice - discountPrice) / retailPrice) * 100
      );
    } else {
      setDiscountPercentage(null);
    }

    if (!discountPrice && !retailPrice) {
      setDiscountPercentage(null);
    }
  }, [discountPrice]);

  const handleCancel = () => {
    navigate(ENDPOINT_INVENTORY);
  };
  const handleSpecificationListChangeName = (e, index) => {
    const list = [...specificationListData];
    console.log(list);
    list[index].name = e;
    setSpecificationListData(list);
  };
  const handleSpecificationListChangeValue = (e, index) => {
    const list = [...specificationListData];
    console.log(list);
    list[index].value = e;
    setSpecificationListData(list);
  };
  const handleSpecificationListRemoveClick = (index) => {
    const list = [...specificationList];
    list.splice(index, 1);
    setSpecificationList(list);
  };

  const handleSpecificationListAddClick = () => {
    setSpecificationList([...specificationList, { name: null, value: [] }]);
    setSpecificationListData([
      ...specificationListData,
      { name: null, value: [] },
    ]);
  };

  const handleVariantListChangeName = (e, index) => {
    const list = [...variantListData];
    console.log(list);
    list[index].name = e;
    setVariantListData(list);
  };

  const handleVariantListChangeOptions = (e, index) => {
    const list = [...variantListData];
    console.log(list);
    list[index].options = e;
    setVariantListData(list);
  };

  const handleVariantListRemoveClick = (index) => {
    const list = [...variantList];
    list.splice(index, 1);
    setVariantList(list);
  };

  useEffect(() => {
    if (selectedImages != null && selectedImages.length > 0) {
      setSelectedImage(selectedImages[selectedImages.length - 1]);
    } else {
      setSelectedImage(null);
    }
  }, [selectedImages]);

  const handleImageRemoveClick = (index) => {
    const list = [...selectedImages];
    list.splice(index, 1);
    setSelectedImages(list);
  };

  const handleImageRemoveClickPrev = (index) => {
    const lastImageIndex = selectedImages.length - 2;
    const list = [...selectedImages];
    list.splice(index, 1);
    setSelectedImages(list);
  };

  const handleImageClick = (index) => {
    setSelectedImage(selectedImages[index]);
  };

  // handle click event of the Add button
  const handleVariantListAddClick = () => {
    setVariantList([...variantList, { name: null, options: [] }]);
    setVariantListData([...variantListData, { name: null, options: [] }]);
  };
  const partCondtionChanged = (event) => {
    console.log(event.target.value);
    setPartCondition(event.target.value);
  };
  const shippingModeChanged = (event) => {
    setShippingMode(event.target.value);
  };

  const removeSelectedCategoriesPrev = (index) => {
    const list = [...selectedCategoriesPrev];
    list.splice(index, 1);
    setSelectedCategoriesPrev(list);
  };
  const removeSelectedTagsPrev = (index) => {
    const list = [...selectedTagsPrev];
    list.splice(index, 1);
    setSelectedTagsPrev(list);
  };
  const removeSelectedVehiclePrev = (index) => {
    const list = [...selectedVehiclesPrev];
    list.splice(index, 1);
    setSelectedVehiclesPrev(list);
  };
  const removeSelectedSpecificationsPrev = (index) => {
    const list = [...selectedSpecificationsPrev];
    list.splice(index, 1);
    setSelectedSpecificationsPrev(list);
  };

  const removeSelectedVariantsPrev = (index) => {
    const list = [...selectedVariationsPrev];
    list.splice(index, 1);
    setSelectedVariationsPrev(list);
  };

  const navigate = useNavigate();

  const sendToServer = async (endpoint) => {
    setProcessing(true);

    setTimeout(() => {
      setProcessing(false);
    }, 90000);

    let imageUrls = [];
    if (selectedImages && selectedImages.length > 0) {
      for (var i = 0; i < selectedImages.length; i++) {
        const sImage = selectedImages[i];
        if (sImage.isPrev) {
          imageUrls.push(sImage.image);
        } else {
          const imageUrl = await handleFileUploadOnFirebaseStorage(
            sImage.image
          );
          imageUrls.push(imageUrl);
          console.log(imageUrl);
        }
      }
    }

    let tags = [];
    if (selectedTagsPrev != null && selectedTagsPrev.length > 0) {
      tags = tags.concat(selectedTagsPrev);
    }
    selectedTags.map((tag) => {
      tags.push(tag.value);
    });
    let categories = [];
    if (selectedCategoriesPrev != null && selectedCategoriesPrev.length > 0) {
      selectedCategoriesPrev.map((category) => {
        categories.push(category.id);
      });
    }

    if (selectedCategories != null && selectedCategories.length > 0) {
      selectedCategories.map((category) => {
        categories.push(category.value);
      });
    }

    let vehicles = [];
    if (selectedVehiclesPrev != null && selectedVehiclesPrev.length > 0) {
      selectedVehiclesPrev.map((vehicle) => {
        vehicles.push(vehicle.id);
      });
    }

    if (selectedVehicles != null && selectedVehicles.length > 0) {
      selectedVehicles.map((vehicle) => {
        vehicles.push(vehicle.value);
      });
    }

    let variations = [];
    if (selectedVariationsPrev != null && selectedVariationsPrev.length > 0) {
      variations = selectedVariationsPrev;
    }
    if (variantListData !== null && variantListData.length > 0) {
      variantListData.map((variation) => {
        const options = [];
        if (variation.options != null && variation.options.length > 0) {
          variation.options.map((option) => options.push(option.value));
          variations.push({ name: variation.name.value, options: options });
        }
      });
    }
    let brand = null;
    if (selectedBrand != null) {
      if (selectedBrand.__isNew__ == undefined) {
        brand = selectedBrand.value;
      } else {
        const brandDetails = await createBrand();
        if (brandDetails != null && brandDetails.id != null) {
          brand = brandDetails.id;
        }
      }
    }
    let specifications = [];
    if (
      selectedSpecificationsPrev != null &&
      selectedSpecificationsPrev.length > 0
    ) {
      selectedSpecificationsPrev.map((spec) => {
        specifications.push({ name: spec.name, value: spec.value });
      });
    }
    if (specificationListData !== null && specificationListData.length > 0) {
      specificationListData.map((spec) => {
        if (
          spec.name != null &&
          spec.name.value != null &&
          spec.name.value.trim().length > 0 &&
          spec.value != null &&
          spec.value.value != null &&
          spec.value.value.trim().length > 0
        ) {
          specifications.push({
            name: spec.name.value,
            value: spec.value.value,
          });
        }
      });
    }

    const params = {
      price: truncateNumber(retailPrice),
      merchant_price: merchantPrice,
      name: partName,
      tags: tags,
      type: partType,
      vehicles: vehicles,
      variations: variations,
      categories: categories,
      description: partDesc,
      condition: partCondition,
    };

    if (expiryDate) {
      params.expiry_date = expiryDate;
    }

    if (weight) {
      params.weight = weight;
    }

    if (shippingMode) {
      params.shipping_mode = shippingMode;
    }

    if (receivePriceWholesale) {
      params.merchant_price_wholesale = receivePriceWholesale;
    }

    if (moq) {
      params.wholesale_moq = moq;
    }

    const dPrice = truncateNumber(discountPrice);
    if (dPrice && !isNaN(dPrice)) {
      params.discount_price = dPrice;
    }

    const wPrice = truncateNumber(priceWholesale);
    if (wPrice && !isNaN(wPrice)) {
      params.wholesale_price = wPrice;
    }
    const sAvail = Number(stockAvailable);
    if (sAvail && !isNaN(sAvail)) {
      params.stock_available = sAvail;
    }

    if (brand != null) {
      params.brand = brand;
    }

    if (specifications.length > 0) {
      params.specifications = specifications;
    } else {
      params.specifications = [];
    }

    if (partNumber != null) {
      params.part_number = partNumber;
    }
    if (aftermarketNumber != null) {
      params.part_number = aftermarketNumber;
    }
    if (oem != null && oem.trim().length > 0) {
      params.oem = oem;
    }

    if (brand != null) {
      params.brand = brand;
    }

    if (imageUrls != null && imageUrls.length > 0) {
      params.thumbnail_urls = imageUrls;
      if (coverImageIndex > -1 && coverImageIndex <= imageUrls.length) {
        params.cover_image_url = imageUrls[coverImageIndex];
      } else {
        params.cover_image_url = imageUrls[0];
      }
    } else {
      params.thumbnail_urls = null;
      params.cover_image_url = null;
    }

    return makeAuthenticatedRequest(
      `/oms/inventory/${inventoryID}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setProcessing(true);
        toast.success("Item succesfully Updated");
      })
      .catch((error) => {
        toast.error("Something went wrong. Could not update part!");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const components = {
    DropdownIndicator: null,
  };

  const handleSubmit = async (event, endpoint) => {
    event.preventDefault();

    if (partName == null || partName.trim().length < 1) {
      toast.error("Enter a valid part name");
      return;
    }
    if (partDesc == null || partDesc.trim().length < 1) {
      toast.error("Enter a valid part description");
      return;
    }

    if (partType == null || partType.trim().length < 1) {
      toast.error("Select a valid part type");
      return;
    }
    if (partCondition == null || partCondition.trim().length < 1) {
      toast.error("Select a valid part condition");
      return;
    }

    const rPrice = truncateNumber(retailPrice);
    if (!rPrice || isNaN(rPrice)) {
      toast.error("Enter a valid retail price");
      return;
    }

    const mPrice = truncateNumber(merchantPrice);
    if (!mPrice || isNaN(mPrice)) {
      toast.error("Enter a valid merchant price");
      return;
    }

    if (stockAvailable == null || stockAvailable < 1) {
      toast.error("Enter available stock");
      return;
    }
    const accountObj = new AccountObj();
    const user = auth.currentUser;

    setProcessing(true);
    if (user) {
      await sendToServer(endpoint);
    } else {
      await getFirebaseTokenThenSignin()
        .then((userCredential) => {
          // Signed in
          // const user = userCredential.user;
          // console.log('firebase user',userCredential)
          // ...
          // return await
          sendToServer();
        })
        .then(() => {
          sendToServer();
        });
    }
  };

  const displayLogs = useMemo(() => {
    let logsToDisplay = inventoryData?.logs || [];
    if (logsToDisplay?.length > 10) {
      return logsToDisplay?.slice(-10).reverse();
    }
    return logsToDisplay.reverse();
  }, [inventoryData]);

  const updateRetailPriceCommission = (value) => {
    if (value) {
      const comm = parseFloat(value);
      const mPrice = parseFloat(merchantPrice);
      const newPrice = mPrice * ((100 + comm) / 100);
      setRetailPrice(parseInt(newPrice));
      setRetailCommission(comm);
    } else {
      setRetailCommission(value);
    }
  };

  const updateRetailPrice = (value) => {
    if (value) {
      const rPrice = parseInt(value);
      if (merchantPrice && parseInt(merchantPrice) > 0) {
        const rCommission = parseFloat((rPrice / merchantPrice) * 100) - 100;
        setRetailCommission(truncateNumber(rCommission));
      }
      setRetailPrice(rPrice);
    } else {
      setRetailPrice(value);
    }
  };

  const updateMerchantPrice = (value) => {
    if (value) {
      const mPrice = parseInt(value);

      if (mPrice > 0) {
        if (retailPrice && parseInt(retailPrice) > 0) {
          const rCommission = parseFloat((retailPrice / mPrice) * 100) - 100;
          setRetailCommission(truncateNumber(rCommission, 2));
        }
        setMerchantPrice(mPrice);
        setError(""); 
      } else {
        setError("Invalid price input. Enter a value greater than zero.");
      }
    } else {
      setMerchantPrice(value);
      setError(""); 
    }
  };

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex flex-row gap-1 cursor-pointer w-[40%] md:w-[10%] px-2 py-2 bg-[#F2F2F4] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 mt-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
          <a
            target="_blank"
            href={`https://marketplace.sparepap.com/part/${inventoryData?.id}`}
          >
            <button className=" w-full px-4 py-2 font-satoshi text-center border rounded-md">
              Preview
            </button>
          </a>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="flex flex-col md:flex-row gap-6 w-full font-satoshi">
            <div className="flex flex-col gap-2 w-full md:w-[70%]">
              <div className="flex flex-col w-full gap-1">
                <label
                  htmlFor=""
                  className={` font-satoshi text-gray-400 font-semibold  `}
                >
                  Title
                </label>
                <input
                  value={partName}
                  onChange={(e) => setPartName(e.target.value)}
                  type="text"
                  className="rounded-md px-4 py-2.5 outline-none border"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {selectedImage ? (
                  <img
                    src={
                      selectedImage.isPrev
                        ? selectedImage.image
                        : URL.createObjectURL(selectedImage.image)
                    }
                    className={`border object-contain rounded h-72`}
                    alt=""
                  />
                ) : (
                  <div>
                    <input
                      type="file"
                      name="file"
                      id="files"
                      multiple
                      ref={refFileInput}
                      accept="image/*"
                      onChange={onFileChange}
                      className="hidden"
                    />
                    <label
                      htmlFor="files"
                      className="flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v12m6-6H6"
                        />
                      </svg>
                    </label>
                  </div>
                )}

                <div className="flex flex-row gap-2">
                  {selectedImages.map((image, i) => (
                    <div key={i} className="flex flex-row">
                      <div class="mt-1">
                        <img
                          src={
                            image.isPrev
                              ? image.image
                              : URL.createObjectURL(image.image)
                          }
                          className={
                            image.image == inventoryData.cover_image_url
                              ? " overflow-hidden rounded-lg object-contain h-20 w-20 border-4 border-teal-400"
                              : " overflow-hidden rounded-lg object-contain h-20 w-20 border border-gray-200"
                          }
                          alt=""
                          onClick={() => handleImageClick(i)}
                        />
                      </div>
                      <span
                        className="text-red-500 p-2 hover:cursor z-50 -ml-8 -mt-2 h-2"
                        onClick={() => handleImageRemoveClick(i)}
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          class="css-tj5bde-Svg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </span>
                    </div>
                  ))}
                  {selectedImage && (
                    <label
                      htmlFor="file-upload"
                      class="relative cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none "
                    >
                      <div class="mt-1 flex justify-center rounded-md p-6">
                        <div class="space-y-1 text-center">
                          <div class="flex text-sm text-gray-600">
                            <span className="font-satoshi bg-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v12m6-6H6"
                                />
                              </svg>
                            </span>
                            <input
                              ref={refFileInput}
                              multiple
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              onChange={onFileChange}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor=""
                  className="font-satoshi text-gray-400 font-semibold"
                >
                  Description
                </label>
                <textarea
                  value={partDesc}
                  onChange={(e) => setPartDesc(e.target.value)}
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className={` outline-none rounded-md px-4 py-2.5 border`}
                ></textarea>
              </div>
              <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                <p className="font-semibold text-gray-400 font-satoshi">
                  Detailed specifications
                </p>
                <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                      Part Number
                    </p>
                    <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                      Brand/Manufacturer
                    </p>
                    {/* <p className='font-satoshi text-[#333B51] font-medium text-[16px]'>Dimensions</p>
                <p className='font-satoshi text-[#333B51] font-medium text-[16px]'>Material</p> */}
                  </div>
                  <div className="col-span-2 flex flex-col gap-2">
                    <input
                      value={partNumber}
                      onChange={(e) => setPartNumber(e.target.value)}
                      type="text"
                      className={`border outline-none px-4 py-2 rounded-md `}
                    />

                    {/* <input
                value={brand}
                onChange={(e) => setBrand(e.target.value)} 
                type="text" className={`border outline-none  px-4 py-2 rounded-md `}  /> */}
                    {selectedBrand !== null && selectedBrand.length > 0 ? (
                      <div>
                        <div>
                          <div className="flex flex-col ">
                            <div className="flex flex-row  flex-wrap mt-2 mb-2 w-full border rounded-md">
                              <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                                <p>
                                  {selectedBrand}&nbsp;
                                  <span
                                    onClick={() => setSelectedBrand(null)}
                                    className="cursor-pointer"
                                  >
                                    &times;
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <CreatableSelect
                        className="p-1 rounded-lg w-full text-[#19223B] font-satoshi text-md"
                        placeholder="Brand"
                        isClearable
                        name="brand"
                        options={brandsData}
                        onChange={(e) => setSelectedBrand(e)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                <p className="text-md font-satoshi font-semibold">
                  Part Type *
                </p>
                <div>
                  <div className="flex flex-row mt-2">
                    <input
                      checked={partType === PartType.OEM}
                      onChange={partTypeChanged}
                      type="radio"
                      value={PartType.OEM}
                      className="mr-1"
                    />
                    <span>OEM</span>
                  </div>

                  <div className="flex flex-row mt-2">
                    <input
                      checked={partType === PartType.AFTER_MARKET}
                      onChange={partTypeChanged}
                      type="radio"
                      value={PartType.AFTER_MARKET}
                      className="mr-1"
                    />
                    <span>Aftermarket</span>
                  </div>
                </div>
              </div>

              <div className={`flex flex-col w-full gap-2  rounded-md p-2 `}>
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Part condition
                </p>

                <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <div
                      className={`flex flex-row ${
                        partCondition === "N"
                          ? "border border-[#333B51]"
                          : "border"
                      } gap-1 rounded-md p-2  `}
                    >
                      <input
                        onChange={(e) => setPartCondition("N")}
                        type="checkbox"
                        className={` ${
                          partCondition === "N" ? "accent-[#333B51]" : ""
                        } `}
                        checked={partCondition === "N"}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        Brand new
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`  ${
                        partCondition === "R"
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        onChange={(e) => setPartCondition("R")}
                        type="checkbox"
                        className={`${
                          partCondition === "R" ? "accent-[#333B51]" : ""
                        } `}
                        checked={partCondition === "R"}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        Refurbished
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`flex flex-row ${
                        partCondition === "U"
                          ? "border border-[#333B51]"
                          : "border"
                      } gap-1 rounded-md p-2`}
                    >
                      <input
                        onChange={(e) => setPartCondition("U")}
                        type="checkbox"
                        className={`${
                          partCondition === "U" ? "accent-[#333B51]" : ""
                        }`}
                        checked={partCondition === "U"}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        Used
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={`flex flex-col w-full gap-2  rounded-md p-2 ${inventoryData?.categories.length !==0 ? 'border':'border border-[#F77B55]'}`}> */}
              <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                <p className="text-md font-satoshi font-semibold">Categories</p>
                {selectedCategoriesPrev !== null &&
                selectedCategoriesPrev.length > 0 ? (
                  <div>
                    <div>
                      <div className="flex flex-col">
                        <div className="flex flex-row  flex-wrap mt-2 mb-2">
                          {selectedCategoriesPrev.map((category, i) => (
                            <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                              <p>
                                {category.name}&nbsp;
                                <span
                                  onClick={() =>
                                    removeSelectedCategoriesPrev(i)
                                  }
                                  className="cursor-pointer"
                                >
                                  &times;
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-col">
                  <Select
                    placeholder="Search or select a category"
                    isMulti
                    name="categories"
                    options={categoriesData}
                    onChange={(e) => setSelectedCategories(e)}
                  />
                </div>
              </div>

              <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                <p className="text-md font-satoshi font-semibold">Tags</p>
                {selectedTagsPrev !== null && selectedTagsPrev.length > 0 ? (
                  <div>
                    <div>
                      <div className="flex flex-col">
                        <div className="flex flex-row  flex-wrap mt-2 mb-2">
                          {selectedTagsPrev.map((tag, i) => (
                            <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                              <p>
                                {tag}&nbsp;
                                <span
                                  onClick={() => removeSelectedTagsPrev(i)}
                                  className="cursor-pointer"
                                >
                                  &times;
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-col">
                  <CreatableSelect
                    components={components}
                    isClearable
                    isMulti
                    placeholder="Type something and press enter..."
                    onChange={(e) => setSelectedTags(e)}
                  />
                </div>
              </div>

              {tags2?.length > 0 && (
                <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                  <p className="text-md font-satoshi font-semibold">Tags2</p>
                  {selectedTagsPrev !== null && selectedTagsPrev.length > 0 ? (
                    <div>
                      <div>
                        <div className="flex flex-col">
                          <div className="flex flex-row  flex-wrap mt-2 mb-2">
                            {tags2.map((tag) => (
                              <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                                <p>
                                  {tag?.name}&nbsp;
                                  <span
                                    onClick={() => handleRemoveTagClick(tag)}
                                    className="cursor-pointer"
                                  >
                                    &times;
                                  </span>
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="flex flex-col">
                    <form action="" onSubmit={(e) => handleSubmitTags2(e)}>
                      <CreatableSelect
                        onFocus={() => createTagsTwoFocus()}
                        isMulti
                        isClearable
                        options={tagOptions}
                        onChange={(value) => handleSelectedTagsChange(value)}
                        placeholder="Type something and press enter..."
                      />
                    </form>
                  </div>
                </div>
              )}

              <div
                className={` ${
                  inventoryData?.vehicles.length < 1 ? "border " : "border"
                } flex flex-col w-full gap-2 border rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Compatibility
                </p>
                <div className="flex flex-col gap-1">
                  <p className="font-satoshi text-[14px] ">
                    This part is compatible with
                  </p>
                  {selectedVehiclesPrev !== null &&
                  selectedVehiclesPrev.length > 0 ? (
                    <div>
                      <div>
                        <div className="flex flex-col">
                          <div className="flex flex-row  flex-wrap mt-2 mb-2">
                            {selectedVehiclesPrev.map((vehicle, i) => (
                              <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                                <p>
                                  {vehicle.name}&nbsp;
                                  <span
                                    onClick={() => removeSelectedVehiclePrev(i)}
                                    className="cursor-pointer"
                                  >
                                    &times;
                                  </span>
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="flex flex-col">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadVehicleOptions}
                      placeholder="Search or select a vehicle"
                      isMulti
                      name="vehicles"
                      onChange={(e) => setSelectedVehicles(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Weight
                </p>
                <div className="flex md:grid flex-col md:grid-cols-4 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <div
                      className={` ${
                        weight <= 5 ? "border border-[#333B51]" : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        name="weight"
                        type="radio"
                        onChange={(e) => setWeight(5)}
                        className={`${weight <= 5 ? "accent-[#333B51]" : ""}`}
                        checked={weight <= 5}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        5kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`${
                        weight > 5 && weight <= 20
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        name="weight"
                        type="radio"
                        onChange={(e) => setWeight(20)}
                        className={` ${
                          weight > 5 && weight <= 20 ? "accent-[#333B51]" : ""
                        } `}
                        checked={weight > 5 && weight <= 20}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        5-20kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={` ${
                        weight > 20 && weight < 50
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        onChange={(e) => setWeight(45)}
                        name="weight"
                        type="radio"
                        className={` ${
                          weight > 20 && weight < 50 ? "accent-[#333B51]" : ""
                        }`}
                        checked={weight > 20 && weight < 50}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        20-50 kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`${
                        weight >= 50 ? "border border-[#333B51]" : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        onChange={(e) => setWeight(50)}
                        name="weight"
                        type="radio"
                        className={` ${weight >= 50 ? "accent-[#333B51]" : ""}`}
                        checked={weight >= 50}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>

                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        {" "}
                        50 kg
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex flex-col w-full gap-2 border rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Mode of transport
                </p>
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[14px] ">
                    What's the recommended way to transport this item?
                  </p>
                </div>

                <div className="flex md:grid flex-col md:grid-cols-2 gap-3">
                  <div
                    className={`col-span-1 flex flex-row ${
                      shippingMode === "Bike"
                        ? "border border-[#333B51]"
                        : "border"
                    } rounded-md px-2 py-2  gap-1`}
                  >
                    <label
                      for="bike"
                      class="relative flex gap-1 w-full cursor-pointer outline-none bg-white font-medium"
                    >
                      <img src="/cdn/images/bike.svg" alt="" />
                      <input
                        onChange={shippingModeChanged}
                        value="Bike"
                        id="bike"
                        name="shipping"
                        type="radio"
                        checked={shippingMode === "Bike"}
                        className="outline-none w-full sr-only"
                      />
                      <p
                        htmlFor=""
                        className={` py-2 font-satoshi text-[16px] ${
                          shippingMode === "Bike"
                            ? "text-[#333B51]"
                            : "text-gray-4"
                        }`}
                      >
                        Motorcycle
                      </p>
                    </label>
                    {/* <input type="radio" className='bg-white border border-white py-2 ' placeholder='Motorcycle' /> */}
                  </div>
                  <div
                    className={`col-span-1 flex w-full flex-row ${
                      shippingMode === "Van"
                        ? "border border-[#333B51]"
                        : "border"
                    } rounded-md px-2 py-2 border gap-1`}
                  >
                    <label
                      for="van"
                      class="relative flex  gap-1 w-full cursor-pointer outline-none bg-white font-medium"
                    >
                      <img src="/cdn/images/van.svg" alt="" />
                      <input
                        onChange={shippingModeChanged}
                        id="van"
                        value="Van"
                        name="shipping"
                        type="radio"
                        checked={shippingMode === "Van"}
                        className="outline-none w-full sr-only"
                      />
                      <p
                        htmlFor=""
                        className={`${
                          shippingMode === "Van"
                            ? "text-[#333B51]"
                            : "text-gray-4"
                        } py-2 font-satoshi text-[16px]`}
                      >
                        Van
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <div className="border border-gray-200 rounded-md px-3 py-4 mt-4">
                <p className="text-md font-satoshi font-semibold">Variants</p>
                {selectedVariationsPrev !== null &&
                selectedVariationsPrev.length > 0 ? (
                  <div>
                    <div>
                      <div className="flex flex-col">
                        <div className="flex flex-row  flex-wrap mt-2 mb-2">
                          {selectedVariationsPrev.map((variant, i) => (
                            <div className="p-1 pr-2 pl-2 border border-gray-200 rounded-md m-1">
                              <p>
                                {variant.name}:{" "}
                                <i>{variant.options.join(",")}</i>&nbsp;
                                <span
                                  onClick={() => removeSelectedVariantsPrev(i)}
                                  className="cursor-pointer"
                                >
                                  &times;
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-col">
                  {variantList.map((variant, i) => (
                    <div className="flex flex-row  flex-wrap mt-2 mb-2">
                      <CreatableSelect
                        components={components}
                        onChange={(e) => handleVariantListChangeName(e, i)}
                        isClearable
                        placeholder="Attribute name"
                        className="w-4/12 mr-2"
                      />
                      <CreatableSelect
                        components={components}
                        onChange={(e) => handleVariantListChangeOptions(e, i)}
                        className="w-7/12"
                        isClearable
                        isMulti
                        placeholder="Attribute values; type something and press enter..."
                      />
                      <span
                        className="text-red-500 p-2 hover:cursor"
                        onClick={() => handleVariantListRemoveClick(i)}
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          class="css-tj5bde-Svg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </span>
                    </div>
                  ))}
                  <div className="flex flex-row  flex-wrap mt-2 mb-2">
                    <div className="pr-3 pt-2 pb-2 pl-3 border border-gray-200 m-1  cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none">
                      <span
                        className="font-bold text-black"
                        onClick={handleVariantListAddClick}
                      >
                        <span className="mr-1 ">&#43;</span>Add a variant
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                  <p className="font-semibold text-gray-400 font-satoshi">
                    Expiry date
                  </p>
                  <DateTimePicker
                    minDate={new Date()}
                    onChange={setExpiryDate}
                    value={expiryDate}
                    className="border border-gray-300 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                  />
                </div>
              </div>

              <div className="flex flex-row gap-2">
                <button
                  onClick={(e) => handleSubmit(e, ENDPOINT_INVENTORY)}
                  className="px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md"
                >
                  {processing ? "Saving ..." : "Save"}
                </button>
              </div>
            </div>

            <div className="sticky top-28 pt-4 flex flex-col w-full pr-2 md:pr-0 md:w-[20%] flex-[1] mb-3">
              <p className="text-[#19223B] font-satoshi text-md font-semibold">
                Price Details
              </p>
              <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">
                <div className={`flex-col `}>
                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Buying/Merchant Price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="1"
                        max="999999999"
                        onChange={(e) => updateMerchantPrice(e.target.value)}
                        value={merchantPrice}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Commission (%)
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        onChange={(e) =>
                          updateRetailPriceCommission(e.target.value)
                        }
                        value={retailCommission}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">%</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Retail Price
                    </label>

                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        onChange={(e) => updateRetailPrice(e.target.value)}
                        value={retailPrice}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Units in stock
                    </label>
                    <div className="flex flex-row ">
                      <input
                        onChange={(e) => setStockAvailable(e.target.value)}
                        value={stockAvailable}
                        type="number"
                        name=""
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">
                <div onClick={() => setSideBarDiscountStatus((open) => !open)}>
                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Discount price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        onChange={(e) => setDiscountPrice(e.target.value)}
                        value={discountPrice}
                        className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        placeholder="13,499"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>
                </div>
                {sideBarDiscountStatus && (
                  <div className={`flex-col `}>
                    <div className="mt-2 mb-2">
                      <label
                        htmlFor="retail"
                        className="block text-sm font-satoshi text-gray-700"
                      >
                        Discount
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          readOnly
                          disabled
                          type="number"
                          min="0"
                          max="100"
                          onChange={(e) =>
                            setDiscountPercentage(e.target.value)
                          }
                          value={discountPercentage}
                          className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                          placeholder="10"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                          <span className="text-gray-500">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-2 mb-2 rounded-md border border-gray-300 p-2 bg-white">
                <div className="flex flex-col">
                  <div
                    onClick={() => setSideBarWholesale((open) => !open)}
                    className="mt-2 mb-2"
                  >
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Wholesale price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        onChange={(e) => setPriceWholesale(e.target.value)}
                        value={priceWholesale}
                        className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        placeholder="13,499"
                      />
                      <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>
                  {sideBarWholesale && (
                    <>
                      <div className="mt-2 mb-2">
                        <label
                          htmlFor="retail"
                          className="block text-sm font-satoshi text-gray-700"
                        >
                          Minimum order quantity
                        </label>
                        <div className="relative ">
                          <input
                            onChange={(e) => setMoq(e.target.value)}
                            value={moq}
                            type="text"
                            name=""
                            className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                            placeholder="0"
                          />
                          <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                            <span className="">units</span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 mb-2">
                        <label
                          htmlFor="retail"
                          className="block text-sm font-satoshi text-gray-700"
                        >
                          Amount you will receive
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <input
                            type="number"
                            min="0"
                            max="999999999"
                            onChange={(e) =>
                              setReceivePriceWholesale(e.target.value)
                            }
                            value={receivePriceWholesale}
                            className="w-full p-1.5 border border-gray-200 bg-[#79C38D33] outline-none appearance-none"
                            placeholder="9,499.30"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                            <span className="text-gray-500">KSH</span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 mb-1">
                        <p className="font-satoshi text-xs">
                          Sparepap takes an {wholesaleCommission}% commission on
                          wholesale purchases above 50 units
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                id="invnotes"
                className={`${
                  openNotes ? "block" : "block"
                } flex flex-col gap-2`}
              >
                {displayLogs?.map((log) => (
                  <div className="flex flex-col py-2 w-full">
                    <div className="flex flex-row gap-2 w-full">
                      <div className="">
                        <Avatar
                          name={
                            log.user?.first_name
                              ? `${log.user?.first_name} ${log.user?.last_name}`
                              : log?.passport
                              ? `${log.passport?.merchant_name}`
                              : log.user?.email
                          }
                          size="50"
                          className="h-12 w-12 mr-2 rounded-full shadow"
                        />
                      </div>
                      <div className="flex flex-col gap-3 flex-1">
                        <div className="flex flex-row gap-2">
                          <p className="font-satoshi text-[#030A1A]  text-[18px]">
                            {log.user?.first_name
                              ? `${log.user?.first_name} ${log.user?.last_name}`
                              : log?.passport
                              ? `${log.passport?.merchant_name}`
                              : log.user?.email}
                          </p>

                          <span>.</span>

                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log.date_created}
                              format="D MMM"
                              trim
                            />
                          </p>
                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log.date_created}
                              format="hh:mm"
                              trim
                            />
                          </p>
                        </div>
                        <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                          <p className="font-satoshi text-[#030A1A]  text-[14px]">
                            {log.system_message}
                          </p>
                        </div>
                        {log.message && (
                          <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                            <p className="font-satoshi text-[#030A1A]  text-[14px]">
                              {log.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default InventoryEdit;
